<template>
  <div>
       <!-- 创建 -->
        <div style="width:700px;margin:0 auto;background:#fff;text-align:left;">

                <div style="text-align:center;">
                <el-input placeholder="请输入内容" v-model="saveData.name"  >
                <template slot="prepend">分类名称</template>
                </el-input>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button  @click="exit();">关 闭</el-button>
                <el-button type="primary" @click="save();">提 交</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            fileUrl: tools.baseFileUpload(),
            baseUrl: tools.baseURL(),
            saveDataInit:null,
            saveData:{
                name:'',
            }
        }
    },
    destroyed(){
        this.saveData = tools.copy(this.saveDataInit);
    },
    methods:{
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        save(){
            //判断是修改还是新增
            if(this.id > 0){
                this.saveData.id = this.id;
                //保存  
                apis.customerCateSave(this.saveData).then((res)=>{
                    if(res.code != 200){
                        this.$message({
                            type:'error',
                            message:res.msg
                        });
                        return;
                    }
                    this.$message({
                        type:'success',
                        message:'保存成功'
                    });
                    //通知列表页更新数据
                    this.fleshList();
                }).catch((err)=>{
                    console.log(err);
                    this.$message('网络不通，请稍候重试-');
                });                
                
                return;
            }
            //新增
            apis.customerCateAdd(this.saveData).then((res)=>{
                if(res.code != 200){
                    this.$message({
                        type:'error',
                        message:res.msg
                    });
                    return;
                }
                //显示分享码
                this.saveComplete = true;
                this.$message({
                    type:'success',
                    message:'添加成功'
                });
                //通知列表页更新数据
                this.fleshList();
            }).catch((err)=>{
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据
            apis.customerCateDetail({id:this.id}).then((res)=>{
               res.data.password = ''; 
                this.saveData = res.data;
                this.saveData.id = this.id;
            }).catch((err)=>{
                console.log(err);
                this.$message('网络错误，获取数据失败');
            });
        }
    }
}
</script>

<style scoped lang="less">
    @import url('../../../assets/less/normal');
    .baseInput{
      width:280px;margin:15px 20px;
  }
  .add_label{
      display:inline-block;margin:15px 0 15px 20px;width:300px;
  }
</style>